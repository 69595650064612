// @flow
import type { UserType, SignupMethodType } from 'types/resources/user';
import type { CountryType } from 'types/resources/country';
import type { OfferListType } from 'types/resources/offerList';
import type { ProductType } from 'types/resources/product';
import type { TransactionType } from 'types/resources/transaction';
import type { PromocodeType } from 'types/resources/promocode';
import type { CurrencyType } from 'types/resources/currency';
import type { ShareType } from 'types/views/share';

import fieldToTitle from 'utils/string/fieldToTitle';
import AlgoliaInsights from 'services/algolia-insights';
import { API } from 'api';
import { cacheGet, cacheSet } from 'utils/localStorageCache';
import type { USER_ONBOARDING_TASK_TYPE } from 'views/onboardingTask/utils';
import { throttle } from '../../utils';
import type { TrackingProperties } from './product';
import { mapProductForTracking, mapBrowseForTracking, mapEditOptionForTracking } from './product';
import { getUTMQueries } from './utils';
import FacebookAnalytics from './facebook';
import FirebaseAnalytics from './firebase';
import TikTokAnalytics from './tiktok';
import ImpactAnalytics from './impact';
import ClevertapAnalytics from './clevertap';

// Ref 1: https://www.charlesfarina.com/login-and-signup-naming-conventions-for-google-analytics/
// Ref 2: https://www.analyticsmania.com/post/google-tag-manager-custom-event-trigger/
class AnalyticsManager {
  firstLoad = true;

  currency: string;

  firebase: typeof FirebaseAnalytics;

  facebook: typeof FacebookAnalytics;

  tiktok: typeof TikTokAnalytics;

  impact: typeof ImpactAnalytics;

  clevertap: typeof ClevertapAnalytics;

  constructor({
    firebase,
    facebook,
    tiktok,
    impact,
    clevertap,
  }: {
    firebase: typeof FirebaseAnalytics,
    facebook: typeof FacebookAnalytics,
    tiktok: typeof TikTokAnalytics,
    impact: typeof ImpactAnalytics,
    clevertap: typeof ClevertapAnalytics,
  }) {
    this.firebase = firebase;
    this.facebook = facebook;
    this.tiktok = tiktok;
    this.impact = impact;
    this.clevertap = clevertap;
  }

  /* * * * */
  // On First page Load, GTM+GA automatically sends a new Pageview event,
  // But on subsequent page navigation, they don't send any new Pageview event. GTM/GA don't handle SPA well.
  // So we hook into the LocationManager to listen to route changes and fire the Pageview event ourself.
  // This PageView event is throttled to skip instant redirects
  // and is delayed with setTimeout to make sure location and document is populated before data is sent.

  pageView = throttle(() => {
    // throttled to skip quick redirects

    const pageViewCount = cacheGet('page_view_count');
    // expiry thirty days
    cacheSet('page_view_count', pageViewCount + 1 || 1, 60 * 24 * 30);

    if (this.firstLoad) {
      setTimeout(() => {
        const utm = getUTMQueries();
        this.clevertap.track('PageView', {
          'Current URL': window.location.href,
          'First Open': true,
          ...utm,
        });
      }, 1e3);
    } else {
      // Clevertap
      this.clevertap.track('PageView', { 'Current URL': window.location.href });
      // First time load
    }
    this.firstLoad = false;
    this.tiktok.pageView();
  }, 200);

  setContext = ({ currency, country }: { currency: CurrencyType, country: CountryType }) => {
    this.currency = currency.code;
    this.firebase.setContext({ currency });
    this.tiktok.setContext({ currency });
    this.impact.setContext({ currency });
    this.facebook.setContext({ currency, country });
    this.clevertap.setContext({ country });
  };

  identify = (user: UserType) => {
    if (user && user.id) {
      // Facebook
      this.facebook.identify(user);
      // Firebase
      this.firebase.setUserId(String(user.id));
      // tiktok
      this.tiktok.identify(user);
      // Clevertap
      this.clevertap.identify(user);
    }
    // Impact
    this.impact.identify(user);
  };

  click = (click: string, properties?: TrackingProperties) => {
    // Clevertap
    this.clevertap.track(`Click ${click}`, properties);
  };

  signup = (user: UserType, method: SignupMethodType, properties?: TrackingProperties) => {
    // Facebook
    this.facebook.lead(user);
    // Firebase
    this.firebase.logSignUp(method);
    // TikTok
    this.tiktok.signUp();
    // Clevertap
    this.clevertap.signup(user, method, properties);
  };

  login = (user: UserType, method: SignupMethodType) => {
    this.identify(user);
    // Firebase
    this.firebase.logLogin(method);
    // Clevertap
    this.clevertap.track('Login', { Method: method });
  };

  logout = () => {
    // Firebase
    this.firebase.logout();
  };

  unsubscribeEmail = (user: UserType, unsubscribe: boolean, reason: any) => {
    // Clevertap
    this.clevertap.unsubscribeEmail(user, unsubscribe, reason);
  };

  addPaymentEvent = (location: any) => {
    // Facebook
    this.facebook.addPaymentInfo();
    // Clevertap
    this.clevertap.track('Add Payment', {
      Location: location === 'info' ? 'Signup' : fieldToTitle(location),
    });
    // TikTok
    // this.tiktok.trackEvent('AddPaymentInfo');
  };

  selectPaymentInfo = (value: number | null, product: ProductType) => {
    // TikTok
    this.tiktok.trackEvent('AddPaymentInfo', value, product);
  };

  profileUpdate = (Form: 'profile' | 'buying' | 'selling' | 'notification' | 'vacation-mode') => {
    // Clevertap
    this.clevertap.track('Profile: Update', { Form });
  };

  referralCodeApplied = (referralCode: string) => {
    // Clevertap
    this.clevertap.track('Referral Code Applied', { 'Referral Code': referralCode });
  };

  referralShare = (type?: ShareType) => {
    // Clevertap
    this.clevertap.track(type ? `Referral Share ${type}` : 'Referral Share');
  };

  referralLinkCopy = () => {
    // Clevertap
    this.clevertap.track('Referral Link Copy');
  };

  // Product Events
  homepageProductCategory = (Category: string) => {
    // Clevertap
    this.clevertap.track(`Homepage Category`, { Category });
  };

  homepageBrandClick = (Brand: string, sub_brand: string) => {
    // Clevertap
    this.clevertap.track(`Homepage Brand Click`, { Brand, 'Sub Brand': sub_brand });
  };

  homepageViewMoreClick = (Section: string) => {
    // Clevertap
    this.clevertap.track(`Homepage View More`, { Section });
  };

  productCardClick = (product: ProductType, Section: string) => {
    const trackingData = { ...mapProductForTracking(product), Section };
    // Clevertap
    this.clevertap.track(`Product Click`, trackingData);
  };

  productImpression = (product: ProductType, Section: string) => {
    const trackingData = { ...mapProductForTracking(product), Section };
    // Clevertap
    this.clevertap.track(`Product Impression`, trackingData);
  };

  productView = (
    product: ProductType,
    { price: _price, ...params }: { [key: string]: string | number }
  ) => {
    const utm = getUTMQueries();
    const price = parseFloat(_price);
    const event = { ...mapProductForTracking(product), ...params, ...utm };

    // Firebase
    this.firebase.logViewItem(product, Number(price || 0));
    // TikTok
    this.tiktok.trackEvent('ViewContent', price, product);
    // Clevertap
    this.clevertap.track(`Product View`, event);
    // Facebook
    price &&
      this.facebook.trackFBPixelForDynamicAds({
        event: 'ViewContent',
        product,
        value: price,
      });
  };

  productWishListed = (product: ProductType, properties: TrackingProperties) => {
    // Firebase
    this.firebase.logAddToWishlist(product);
    // Clevertap
    this.clevertap.track(`Product Wishlisted`, properties);
    // Facebook
    this.facebook.addToWishlist(product);
    // TikTok
    this.tiktok.addToWishlist(product);
  };

  browseView = (properties: any) => {
    // Clevertap
    this.clevertap.track(`Browse View`, mapBrowseForTracking(properties));
  };

  productSearch = (operation: string, search: string, total: number) => {
    // Facebook
    this.facebook.search(search);
    // TikTok
    this.tiktok.search(search);
    // Firebase
    this.firebase.logSearch(search);
    // Clevertap
    this.clevertap.productSearch(operation, search, total);
  };

  productSearchView = (search: string, total: number) => {
    const trackingData = {
      Term: search,
      'Term Length': search.length,
      '# of Results': total,
    };
    // Clevertap
    this.clevertap.track(`Search View`, trackingData);
  };

  productShare = (product: ProductType) => {
    // Clevertap
    this.clevertap.track('Product Share', mapProductForTracking(product));
  };

  productRequest = (properties: TrackingProperties) => {
    this.clevertap.track('Product Request: Submit', properties);
  };

  sizeSelect = (operation: string, product: ProductType, size: string) => {
    const trackingData = {
      ...mapProductForTracking(product),
      Size: size,
    };
    // Clevertap
    this.clevertap.track(`${operation} Size Select`, trackingData);
  };

  listSelect = (listId: number, product: ProductType) => {
    // Clevertap
    this.clevertap.track(`Buy List Select`, {
      'List ID': listId,
      ...mapProductForTracking(product),
    });
  };

  promocodeApply = (
    product: ProductType,
    promocode: PromocodeType,
    buy: TransactionType & OfferListType & any
  ) => {
    // Firebase
    this.firebase.logSelectPromotion(product, promocode, buy);
  };

  // Buy/Offer
  buyInitiate = (product: ProductType) => {
    // Clevertap
    this.clevertap.track(`Buy Initiate`, mapProductForTracking(product));
  };

  buyPromoSelect = (
    product: ProductType,
    buy: TransactionType & OfferListType & any,
    promocode?: PromocodeType
  ) => {
    // Firebase
    if (promocode && promocode.id) this.firebase.logViewPromotion(product, buy, promocode);
    const trackingData = {
      ...mapProductForTracking(product),
      ...(promocode ? { Promocode: promocode.code } : {}),
    };
    // Clevertap
    this.clevertap.track(`Buy Promocode Select`, trackingData);
  };

  initiateCheckout = ({
    product,
    buy,
  }: {
    product: ProductType,
    buy: TransactionType & OfferListType & any,
  }) => {
    // Facebook
    this.facebook.initiateCheckout({
      product,
      value: buy?.local_price || buy?.offer_price_local,
    });
    // Firebase
    this.firebase.logBeginCheckout(product, buy);
    // Clevertap
    const trackingData = {
      ...mapProductForTracking(product),
      Size: buy.size,
      'Product Price': buy?.local_price || buy?.offer_price_local,
      Operation: buy.isOffer ? 'Offer' : 'Purchase',
    };
    this.clevertap.track(`Initiate Checkout`, trackingData);
  };

  // unused
  removeFromCart = (product: ProductType, buy: TransactionType & OfferListType & any) =>
    this.firebase.logRemoveFromCart(product, buy);

  buyOfferConfirm = ({
    buy,
    product,
    operation,
    view,
    properties,
    user,
  }: {
    buy: TransactionType & OfferListType & any,
    product: ProductType,
    operation: 'Offer' | 'Purchase',
    view: 'Confirm' | 'Review' | 'Confirm: Initiate',
    properties?: TrackingProperties,
    user?: UserType,
  }) => {
    if (!product.id) return;

    const isBuy = operation === 'Purchase';
    if (isBuy && view === 'Confirm' && buy.created_at) {
      const createdAtTimestamp = new Date(buy.created_at).getTime();
      const oneHourAgo = Date.now() - 1000 * 60 * 60;
      const isWithinLastHour = createdAtTimestamp > oneHourAgo;
      if (!isWithinLastHour) {
        return;
      }
      const key = 'last_purchase_refs';
      const purchaseRefs = cacheGet(key) || [];
      if (purchaseRefs.includes(buy.ref)) {
        return;
      }
      purchaseRefs.push(buy.ref);
      cacheSet(key, purchaseRefs);
    }

    const trackingData = {
      ...mapProductForTracking(product),
      Size: buy.size,
      'Delivery Fee': isBuy ? buy.fee_delivery : buy.fees.delivery,
      'Processing Fee': isBuy ? buy.fee_processing_buy : buy.fees.processing_buy,
      'Promocode Discount': buy.promocode_value,
      'Payment Method': buy.payment_method,
      'Product Price': buy.local_price || buy.offer_price_local,
      Editing: buy.isEdit,
      ...properties,
    };
    // Clevertap
    this.clevertap.track(`${operation} ${view}`, trackingData);

    if (operation === 'Purchase' && view === 'Review') {
      // Firebase
      this.firebase.logAddToCart(product, { ...buy, isOffer: !isBuy }, trackingData);
      // Facebook
      this.facebook.trackFBPixelForDynamicAds({
        event: 'AddToCart',
        product,
        value: buy?.local_price || buy?.offer_price_local,
      });
      // TikTok
      this.tiktok.trackEvent('AddToCart', buy?.local_price || buy?.offer_price_local, product);
    }
    if (view === 'Confirm') {
      AlgoliaInsights.productConversion({ name_slug: product.name_slug, mode: operation });
    }

    if (operation === 'Purchase' && view === 'Confirm') {
      // Firebase
      this.firebase.logPurchase({ product, buy: { ...buy, isOffer: !isBuy }, user });
      // Facebook
      this.facebook.trackFBPixelForDynamicAds({
        event: 'Purchase',
        product,
        value: buy?.local_price || buy?.offer_price_local,
        eventID: buy.ref,
      });
      // TikTok
      this.tiktok.trackEvent(
        'CompletePayment',
        buy?.local_price || buy?.offer_price_local,
        product
      );
      // Impact
      this.impact.purchaseConfirm({ product, buy, user });

      // random event tag given by Frankie Ma
      if (window.gtag)
        window.gtag('event', 'conversion', {
          send_to: 'AW-873334207/CkKBCP2wptgZEL-LuKAD',
          value: buy.local_price || buy.offer_price_local,
          currency: this.currency,
          transaction_id: '',
        });
    }

    if (operation === 'Offer' && view === 'Confirm') {
      API.post(`me/offer-lists/${buy.id}/log`, trackingData);
    }

    if (view === 'Review' && product.id && buy.size && user?.id) {
      API.post('misc/purchase-offer-drop-off-track', {
        product_id: product.id,
        size: buy.size,
        action: operation,
      }).catch(() => null);
    }
  };

  // Sell/List
  sellInitiate = (product: ProductType) => {
    // Facebook
    this.facebook.sellInitiate({ product });
    // Clevertap
    this.clevertap.track('Sell Initiate', mapProductForTracking(product));
  };

  sellListConfirm = (
    sell: (TransactionType & OfferListType) | any,
    product: ProductType,
    seller: UserType,
    operation: 'List' | 'Sale' | 'Consignment',
    view: 'Confirm' | 'Review',
    properties?: TrackingProperties
  ) => {
    const trackingData = {
      ...mapProductForTracking(product),
      Size: sell.size,
      ...(operation === 'Consignment'
        ? {}
        : {
            'Seller Fee':
              ((operation === 'Sale' ? sell.fee_selling : sell.fees.selling) * 100) /
              (sell.local_price || sell.list_price_local),
          }),
      'Product Price': sell.local_price || sell.list_price_local,
      'Shipping From Country': seller.shipping_country.shortcode,
      Editing: sell.isEdit,
      ...properties,
    };
    // Clevertap
    this.clevertap.track(`${operation} ${view}`, trackingData);
    // Facebook
    this.facebook.sellListConfirm({
      operation,
      view,
      product,
      value: sell.local_price || sell.list_price_local,
    });
    if (view === 'Confirm') {
      AlgoliaInsights.productConversion({ name_slug: product.name_slug, mode: operation });
    }

    if (operation === 'List' && view === 'Confirm') {
      API.post(`me/offer-lists/${sell.id}/log`, trackingData);
    }
  };

  deleteOfferList = (mode: string, offerList: any) => {
    const offerListId =
      mode === 'Offer' ? { 'Offer ID': offerList.id } : { 'List ID': offerList.id };
    const trackingData = {
      Size: offerList.size,
      'Product ID': offerList.product_id,
      'Product Price': offerList.local_price,
      ...offerListId,
    };
    // Clevertap
    this.clevertap.track(`Delete ${mode}`, trackingData);
  };

  orderView = (Type: string) => {
    // Clevertap
    this.clevertap.track(`Order View`, { Type });
  };

  resellStorageClick = (product: ProductType, user_id: number) => {
    const trackingData = { ...mapProductForTracking(product), 'User ID': user_id };
    // Clevertap
    this.clevertap.track(`Resell/Storage Click`, trackingData);
  };

  bulkListEdit = ({
    operation,
    edit_option,
    edit_value,
    updated_lists,
  }: {
    operation: 'Confirm' | 'Review',
    edit_option: 'increaseByValue' | 'decreaseByValue' | 'beatLowestListByValue' | 'setToValue',
    edit_value: number,
    updated_lists?: {
      id: number,
      size: string,
      product_id: number,
      new_price: number,
      old_price: number,
    }[],
  }) => {
    let newPrices = [];
    let oldPrices = [];
    const UPDATE_LISTS_DATA_LIMIT = 10;
    if (updated_lists) {
      const lists = updated_lists.splice(0, UPDATE_LISTS_DATA_LIMIT);
      newPrices = lists.map(l => l.new_price);
      oldPrices = lists.map(l => l.old_price);
    }
    const trackingData = {
      'Edit Option': mapEditOptionForTracking[edit_option],
      'Edit Value': edit_value,
      ...(operation === 'Confirm'
        ? {
            'New Lists Price': JSON.stringify(newPrices),
            'Old Lists Price': JSON.stringify(oldPrices),
          }
        : {}),
    };
    // Clevertap
    this.clevertap.track(`Bulk List ${operation}`, trackingData);
  };

  lookbookPostCreated = (properties: TrackingProperties) => {
    // Clevertap
    this.clevertap.track(`Lookbook Post Created`, properties);
  };

  contactUsClick = () => {
    // Clevertap
    this.clevertap.track('Click Contact Us');
    // Facebook
    this.facebook.contact();
  };

  campaignTrack = (
    name: 'SingleDay2022' | 'CNY2023' | 'CNYTW2023' | 'CNY2024' | 'Valentine2024',
    event: string,
    properties?: TrackingProperties
  ) => {
    // Clevertap
    this.clevertap.track(`${name} : ${event}`, {
      ...properties,
    });
  };

  onboardingTask = (event: 'Click' | 'Complete', task: USER_ONBOARDING_TASK_TYPE) => {
    this.clevertap.track(`${event} Onboarding Task`, { Task: task });
  };

  buttonClick = (name: 'Auth Bar Signup' | 'Auth Bar Login', properties?: TrackingProperties) => {
    const trackingData = {
      'Button Name': name,
      ...properties,
    };
    // Clevertap
    this.clevertap.track(`Button Click`, { ...trackingData, 'Current URL': window.location.href });
  };

  socialWidgetClick = (
    name: 'instagram' | 'facebook' | 'tiktok',
    properties?: TrackingProperties
  ) => {
    const trackingData = {
      'Widget Name': name,
      ...properties,
    };
    // Clevertap
    this.clevertap.track(`Social Widget Click`, {
      ...trackingData,
      'Current URL': window.location.href,
    });
  };

  // A/B Testing
  abTest = (experimentName: string, variantName: string) => {
    const event = {
      'Experiment Name': experimentName,
      'Variant Name': variantName,
    };
    // Clevertap
    this.clevertap.track(`A/B Testing`, event);
  };
}

const Analytics = new AnalyticsManager({
  firebase: FirebaseAnalytics,
  facebook: FacebookAnalytics,
  tiktok: TikTokAnalytics,
  impact: ImpactAnalytics,
  clevertap: ClevertapAnalytics,
});

export default Analytics;
