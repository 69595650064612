// @flow
import React, { Suspense } from 'react';
import { Router, Redirect } from '@reach/router';

import DelayedSpinner from 'components/misc/DelayedSpinner';

import PrivateRoute from 'components/misc/PrivateRoute';
import { browseDataFlat } from 'store/constants/browse';
import safeLazy from 'utils/safeLazy';
import RedirectOut from 'views/events/RedirectOut';

const Auth = safeLazy(() => import('views/auth/Auth'));
const VerifyEmail = safeLazy(() => import('views/auth/VerifyEmail'));
const Home = safeLazy(() => import('views/home/Home'));
const Browse = safeLazy(() => import('views/browse'));
const BrowseCuratedLists = safeLazy(() => import('views/curated-lists'));
const Referrals = safeLazy(() => import('views/referrals'));
const LookbookPostView = safeLazy(() => import('views/lookbook/LookbookPostView'));
const Product = safeLazy(() => import('views/product'));
const ProductRequest = safeLazy(() => import('views/product/ProductRequest'));
const ProductSelling = safeLazy(() => import('views/product/ProductSelling'));
const ProductRedirect = safeLazy(() => import('views/product/ProductRedirect'));
const LoginSignup = safeLazy(() => import('views/auth/LoginSignupView'));
const PasswordView = safeLazy(() => import('views/auth/PasswordView'));
const UserDashboard = safeLazy(() => import('views/user'));
const AuthenticateForMe = safeLazy(() => import('views/authenticateforme'));
const OnboardingTask = safeLazy(() => import('views/onboardingTask'));

const About = safeLazy(() => import('views/info/About'));
const Careers = safeLazy(() => import('views/info/Careers'));
const Terms = safeLazy(() => import('views/info/Terms'));
const PaidyPay = safeLazy(() => import('views/payments/PaidyPay'));
const PaypalPay = safeLazy(() => import('views/payments/PaypalPay'));
const TransactionLaws = safeLazy(() => import('views/info/TransactionLaws'));
const TransactionLawsTw = safeLazy(() => import('views/info/TransactionLawsTw'));
const Privacy = safeLazy(() => import('views/info/Privacy'));
const ReturnAndRefund = safeLazy(() => import('views/info/ReturnAndRefund'));
const ProductStandards = safeLazy(() => import('views/info/ProductStandards'));
const BecomeASeller = safeLazy(() => import('views/info/BecomeASeller'));
const ShippingInfo = safeLazy(() => import('views/info/ShippingInfo'));
const SellingInfo = safeLazy(() => import('views/info/SellingInfo'));
const NotFound = safeLazy(() => import('views/info/NotFound'));
const HowWeAuthenticate = safeLazy(() => import('views/info/HowWeAuthenticate'));
const MobileAppDownload = safeLazy(() => import('views/info/MobileAppDownload'));
const Support = safeLazy(() => import('views/info/Support'));
const AboutReviews = safeLazy(() => import('views/info/AboutReviews'));
const RecentlyViewedProducts = safeLazy(() => import('views/user/RecentlyViewedProducts'));
const RecommendedProducts = safeLazy(() => import('views/user/RecommendedProducts'));

// const FlashSale = safeLazy(() => import('views/events/common/flash-sale'));
// Events - 2019
const Double112019 = safeLazy(() => import('views/events/2019/double11'));
const BlackFriday = safeLazy(() => import('views/events/2019/blackfriday'));
const Christmas2019 = safeLazy(() => import('views/events/2019/christmas2019'));
// Events - 2020
const NewYear2020 = safeLazy(() => import('views/events/2020/ny2020'));
const CNY2020 = safeLazy(() => import('views/events/2020/cny2020'));
const SEA2020 = safeLazy(() => import('views/events/2020/sea2020'));
const TKCustoms2020 = safeLazy(() => import('views/events/2020/tkcustoms2020'));
const Double112020 = safeLazy(() => import('views/events/2020/double11'));
const BlackFriday2020 = safeLazy(() => import('views/events/2020/blackfriday2020'));
const YearEnd2020 = safeLazy(() => import('views/events/2020/yearend2020'));
const CNY2021 = safeLazy(() => import('views/events/2021/cny2021'));
const SellerAPIDocs = safeLazy(() => import('views/user/selling/SellerAPIDocs'));
const NBAGames = safeLazy(() => import('views/events/2022/nbagames'));
const StealOfTheDay = safeLazy(() => import('views/events/2022/stealoftheday'));
const SnoopCampaign = safeLazy(() => import('views/events/2022/SnoopCampaign'));
const PartnerSales = safeLazy(() => import('views/events/2022/partnersales'));
const TravelWithNS = safeLazy(() => import('views/events/2022/travelwithns'));
const Double112022 = safeLazy(() => import('views/events/2022/double11'));
// Events - 2023
const CNY2023 = safeLazy(() => import('views/events/2023/cny2023'));
const CNYTW2023 = safeLazy(() => import('views/events/2023/cny2023/cnyTW'));
// Events - 2024
const CNY2024 = safeLazy(() => import('views/events/2024/cny2024'));
const Valentine2024 = safeLazy(() => import('views/events/2024/valentine2024'));
// embeds
const EmbedIframe = safeLazy(() => import('components/misc/EmbedIframe'));
const FlashCoffee = safeLazy(() => import('views/events/2022/flashCoffee'));
const EmbedDripWeekend = safeLazy(() => import('views/events/2022/embedDripWeekend'));
const EmbedTenTenImport = import('views/events/2022/embedTenTen');
const EmbedTenTen = safeLazy(() => EmbedTenTenImport.then(m => ({ default: m.EmbedTenTen })));
const EmbedTenTenMY = safeLazy(() => EmbedTenTenImport.then(m => ({ default: m.EmbedTenTenMY })));
const EmbedTenTenTW = safeLazy(() => EmbedTenTenImport.then(m => ({ default: m.EmbedTenTenTW })));

const SneakerLahMY = safeLazy(() => import('views/events/2022/sneakeerLah'));
const SneakerLahMYStorageDash = safeLazy(() => import('views/events/2022/sneakeerLah/StorageDash'));
const KanyeAdidas = safeLazy(() => import('views/events/2022/kanyeVsAdidas'));

const Routes = () => (
  <Suspense fallback={<DelayedSpinner />}>
    <Router primary={false}>
      <Home path="/" />
      <Redirect from="/sneakers/yeezy" to="/sneakers/adidas/yeezy" />
      <RedirectOut path="/social/*" />
      <LookbookPostView path="lookbook/posts/:post_ref" />
      {!window.isNativeApp && <Redirect from="/raffle/*" to="/mobile-app" />}
      {!window.isNativeApp && <Redirect from="/lookbook/*" to="/mobile-app" />}
      {!window.isNativeApp && <Redirect from="/raffles/*" to="/mobile-app" />}
      {!window.isNativeApp && <Redirect from="/dashboard/daily-quest" to="/mobile-app" />}
      <Browse path="search" currentCategory={{ url: 'search' }} />
      <Browse path="browse" currentCategory={{ url: 'browse' }} />
      <Browse path="collection/:collectionTag" currentCategory={{ url: 'collection' }} />
      {browseDataFlat.map(category => (
        <React.Fragment key={category.url}>
          <Browse path={encodeURI(category.url)} currentCategory={category} />
        </React.Fragment>
      ))}
      <BrowseCuratedLists path="curated/" />
      <Auth path="auth/*" />
      <Referrals path="/referrals" />
      <PaidyPay path="pay/paidy" />
      <PaypalPay path="pay/paypal" />
      <LoginSignup path="login" method="login" />
      <LoginSignup path="signup" method="signup" />
      <PasswordView path="password/*" />
      <VerifyEmail path="verify" />
      <PrivateRoute as={UserDashboard} path="dashboard/*" />
      <PrivateRoute as={SellerAPIDocs} path="seller-api-docs" />
      <PrivateRoute as={SneakerLahMYStorageDash} path="sell-at-sneakerlah" />
      <PrivateRoute as={RecentlyViewedProducts} path="recently-viewed" />
      <PrivateRoute as={RecommendedProducts} path="recommended-for-you" />
      <PrivateRoute as={ProductRequest} path="product-request" />
      <AuthenticateForMe path="authenticateforme/*" />
      <OnboardingTask path="onboarding-task/*" />
      <About path="about" />
      <AboutReviews path="about/reviews" />
      <Careers path="careers" />
      <Terms path="terms" />
      <Support path="support" />
      <TransactionLaws path="tokutei" />
      <TransactionLawsTw path="tw-branches" />
      <Privacy path="privacy" />
      <ReturnAndRefund path="info-return-and-refund" />
      <BecomeASeller path="info-become-a-seller" />
      <ProductStandards path="info-product-standards" />
      <ShippingInfo path="shipping-info" />
      <SellingInfo path="selling-info" />
      <MobileAppDownload path="mobile-app" />
      <HowWeAuthenticate path="authentication" />
      {/* <FlashSale path="flash-sales/:productId" /> */}
      {/* events - 2019 */}
      <Double112019 path="doubleeleven2019" />
      <BlackFriday path="blackfriday2019" />
      <Christmas2019 path="christmas2019/*" />
      {/* events - 2020 */}
      <NewYear2020 path="newyear2020/*" />
      <CNY2020 path="cny2020/*" />
      <SEA2020 path="sea2020/*" />
      <TKCustoms2020 path="dyoaf1" />
      <TKCustoms2020 path="DYOAF1" />
      <Double112020 path="singlesday2020" />
      <BlackFriday2020 path="blackfriday-cybermonday-2020" />
      <YearEnd2020 path="yearend2020/*" />
      {/* events - 2021 */}
      <CNY2021 path="cny2021" />
      {/* events - 2022 */}
      <KanyeAdidas path="kanye-vs-adidas" />
      <PartnerSales path="partner-sales/*" />
      <SnoopCampaign path="campaigndropit" />
      <NBAGames path="nba2022" />
      <StealOfTheDay path="stealoftheday2022" />
      <TravelWithNS path="where-to-next" />
      <FlashCoffee path="flash-coffee" />
      <EmbedDripWeekend path="novelship-drip-weekend" />
      <EmbedTenTen path="10-10-scratch-win" />
      <EmbedTenTenMY path="10-10-scratch-win-my" />
      <EmbedTenTenTW path="10-10-scratch-win-tw" />
      <EmbedIframe
        path="halloween"
        src="https://novelship.com/news/halloween/"
        title="Novelship Halloween"
      />
      <EmbedIframe
        path="flash-coffee-tw"
        src="https://novelship.com/news/novelship-tw-x-flash-coffee/"
        title="Novelship Flash Coffee"
      />
      <EmbedIframe
        path="nba-raffle-tw"
        src="https://novelship.com/news/nba-raffle-tw/"
        title="Novelship NBA Raffle"
      />
      <SneakerLahMY path="sneakerlah-raffle-malaysia/" />
      <Double112022 path="singles-day-sale" />
      <CNYTW2023 path="tw-cny-campaign-2023" />
      <CNY2023 path="cny-campaign-2023" />
      {/* events - 2024 */}
      <CNY2024 path="cny-campaign-2024" />
      <Valentine2024 path="valentine-2024" />
      <ProductRedirect path="p/:productId" />
      <ProductSelling path="sell" />
      <Product path=":productSlug/*" />
      <NotFound path="not-found" />
    </Router>
  </Suspense>
);

export default Routes;
